import { render, staticRenderFns } from "./commodity.vue?vue&type=template&id=530bb4eb&scoped=true"
import script from "./commodity.vue?vue&type=script&lang=js"
export * from "./commodity.vue?vue&type=script&lang=js"
import style0 from "./commodity.vue?vue&type=style&index=0&id=530bb4eb&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "530bb4eb",
  null
  
)

component.options.__file = "commodity.vue"
export default component.exports